import { CircularProgress, Toolbar, Tooltip } from "@mui/material";
import { useState } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import ActionBarComponentProps from "./calender";
import Slider from "react-slick";
import { ReactNormailVideoPlayer } from "src/components";
import Iconify from "src/components/Iconify";

import {
  addMeditationDailyApi,
  listMeditationDailyApi,
  listMeditationVideos,
} from "src/DAL/MeditationDaily/MeditationDaily";
import MeditationVideos from "./MeditationVideos";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loadingform: {
    marginLeft: "50%",
    marginTop: "5%",
    marginBottom: "5%",
  },
}));

export default function DailyMeditation() {
  const { contentSettingData, handleGeneralSettingData } = useContentSetting();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [recordings, setRecordings] = useState([]);
  const [watchedVideos, setWatchedVideos] = useState([]);
  // const [allCoins, setAllCoins] = useState(0);

  const [diaryDate, setDiaryDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isVideosLoading, setIsVideosLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [firstDate, setFirstDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedObject, setSelectedObject] = useState("");
  const [meditationList, setMeditationList] = useState([]);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const CustomNextArrow = (props) => (
    <Iconify
      className="custom-next-arrow"
      onClick={props.onClick}
      icon={"ooui:next-ltr"}
      width={25}
      height={25}
    />
  );

  // Custom Previous button component
  const CustomPrevArrow = (props) => (
    <Iconify
      className="custom-prev-arrow"
      onClick={props.onClick}
      icon={"ooui:next-rtl"}
      width={25}
      height={25}
    />
  );
  let set_infinite = true;
  if (recordings.length < 3) {
    set_infinite = false;
  }

  var settings = {
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    dots: false,
    autoplay: true,
    infinite: set_infinite,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleChangeDate = (value) => {
    const startDate = new Date(value.$d);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDiaryDate(formattedDate);
    // setDiaryDate(moment(value.$d).format("YYYY-MM-DD"));
    const foundObject = meditationList.find(
      (item) => item.date === formattedDate
    );
    setSelectedObject(foundObject);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData = {
      meditation_ids: watchedVideos,
      date: diaryDate,
    };
    console.log(postData, "postData");
    if (watchedVideos.length == 0) {
      enqueueSnackbar("Watch Atleast One Video", { variant: "error" });
    } else {
      setIsSubmit(true);
      const result = await addMeditationDailyApi(postData);
      console.log(result, "resultresultresultresult");
      if (result.code == 200) {
        handleGeneralSettingData(result?.members_coins);
        setWatchedVideos([]);
        getDailyGratitude();
        enqueueSnackbar(result.message, { variant: "success" });
        setIsSubmit(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsSubmit(false);
      }
    }
  };
  const isDateAvailable = () => {
    const isDateMatching = meditationList?.some((item) => {
      const itemDate = moment(item.date).format("YYYY-MM-DD");
      // Check if the item date matches the given date
      return itemDate === diaryDate;
    });

    if (isDateMatching) {
      setDisableButton(isDateMatching);
    } else {
      setDisableButton(isDateMatching);
    }

    return isDateMatching;
  };

  const getDailyGratitude = async () => {
    setIsLoading(true);

    const result = await listMeditationDailyApi();

    if (result.code === 200) {
      setMeditationList(result.meditations);
      let allCoins =
        result.member.affirmation_coins +
        result.member.challenges_coins +
        result.member.goal_coins_count +
        result.member.gratitude_coins +
        result.member.meditation_coins +
        result.member.reward_affirmation_coins +
        result.member.reward_attitude_coins +
        result.member.reward_bonus_coins +
        result.member.reward_gratitude_coins +
        result.member.reward_meditation_coins;

      if (result.meditation_start_date) {
        setFirstDate(moment(result.meditation_start_date).format("YYYY-MM-DD"));
      }
      handleGeneralSettingData(allCoins);
      const isDateMatching = result.meditations?.some((item) => {
        const itemDate = moment(item.date).format("YYYY-MM-DD");
        // Check if the item date matches the given date
        return itemDate === diaryDate;
      });
      let todayDate = diaryDate;
      const foundObject = result.meditations.find(
        (item) => item.date === todayDate
      );
      setSelectedObject(foundObject);

      if (isDateMatching) {
        setDisableButton(isDateMatching);
      } else {
        setDisableButton(isDateMatching);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getMeditationVideos = async () => {
    setIsVideosLoading(true);
    const result = await listMeditationVideos();
    if (result.code === 200) {
      setRecordings(result.meditations);
      setIsVideosLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsVideosLoading(false);
    }
  };
  useEffect(() => {
    setCurrentPlayingIndex(null);
    setWatchedVideos([]);
    getMeditationVideos();
    isDateAvailable();
  }, [diaryDate]);
  useEffect(() => {
    getDailyGratitude();
    isDateAvailable();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <section className="container dynamite-diary">
        <div className="row">
          <div className="col-lg-6">
            <PageDescription
              parameter="daily_meditation_heading"
              else_title="Daily Meditation"
            />
          </div>
          <div className="col-lg-6 text-end"></div>
        </div>
        <section className="container top-section-video mt-3 mb-lg-3">
          <div className="row pt-70" id="assessment-container">
            <div className="col-lg-7 col-md-6 mb-2">
              <ReactNormailVideoPlayer
                url={contentSettingData?.daily_meditation_url}
              />
            </div>
            <div className="col-lg-5 col-md-6 text-md-start text-center">
              <div
                className="d-flex calender w-100 full-open-calender lg-mt-4 mb-2"
                style={{
                  height: "97%",
                }}
              >
                <ActionBarComponentProps
                  diaryDate={diaryDate}
                  setDiaryDate={setDiaryDate}
                  handleChangeDate={handleChangeDate}
                  availableDates={meditationList}
                  firstDiaryDate={firstDate}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="container mt-lg-0 mt-4">
          {isVideosLoading ? (
            <>
              <CircularProgress
                className={classes.loadingform}
                color="primary"
              />
            </>
          ) : recordings.length == 0 ? (
            <>
              <RecordNotFound title="Meditation Videos Not Found" />
            </>
          ) : (
            <>
              {/* {recordings.length <= 3 && window.innerWidth > 600 ? (
                <div className="row">
                  {recordings &&
                    recordings.map((item, i) => (
                      <div className="col-lg-4 col-12 h-100 mt-lg-0 mt-4">
                        <MeditationVideos
                          title={item.title}
                          videoUrl={item.video_url}
                          value={item}
                          watchedVideos={watchedVideos}
                          setWatchedVideos={setWatchedVideos}
                          setCurrentPlayingIndex={setCurrentPlayingIndex}
                          currentPlayingIndex={currentPlayingIndex}
                          selectedObject={selectedObject}
                          index={i}
                        />
                      </div>
                    ))}
                </div>
              ) : (
               
              )} */}
              <Slider {...settings}>
                {recordings &&
                  recordings.map((item, i) => (
                    <div className="me-5 h-100">
                      <MeditationVideos
                        title={item.title}
                        videoUrl={item.video_url}
                        value={item}
                        watchedVideos={watchedVideos}
                        setWatchedVideos={setWatchedVideos}
                        setCurrentPlayingIndex={setCurrentPlayingIndex}
                        currentPlayingIndex={currentPlayingIndex}
                        selectedObject={selectedObject}
                        index={i}
                      />
                    </div>
                  ))}
              </Slider>
            </>
          )}
          {recordings.length > 0 && (
            <div className="row mt-2" id="">
              <div className="col-12">
                <Tooltip
                  title={
                    disableButton
                      ? `Response for ${diaryDate} is already Submitted.`
                      : ""
                  }
                >
                  <button
                    className="small-contained-button float-end mt-1"
                    disabled={disableButton}
                    onClick={handleSubmit}
                  >
                    {isSubmit ? "Submitting..." : "Submit"}
                  </button>
                </Tooltip>
              </div>
              <div className="col-12">
                <p className="float-end mt-1 meditaion-date">{diaryDate}</p>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
