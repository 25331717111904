import { CircularProgress, TextareaAutosize, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import moment from "moment";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CustomConfirmation from "src/components/CustomConfirmation";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import ActionBarComponentProps from "./calender";
import {
  addGratitudeDailyApi,
  deleteGratitudeDailyApi,
  listGratitudeDailyApi,
  updateGratitudeDailyApi,
} from "src/DAL/GratitudeDaily/GratitudeDaily";
import { ReactNormailVideoPlayer } from "src/components";
import CustomPopover from "src/components/CustomPopover";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loadingform: {
    marginLeft: "50%",
    marginTop: "5%",
    marginBottom: "5%",
  },
}));

export default function DailyGratitude() {
  const { contentSettingData, handleGeneralSettingData } = useContentSetting();
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const textareaRef = useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [addNewArray, setaddNewArray] = useState([
    { option: "" },
    { option: "" },
  ]);
  const [recent, setRecent] = useState([]);
  const [diaryDate, setDiaryDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [firstDate, setFirstDate] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [objectDataAvailable, setObjectDataAvailable] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [editValue, setEditValue] = useState("");

  const handleAdd = (index) => {
    const addedElement = [...addNewArray];
    addedElement.splice(index + 1, 0, { option: "" });
    setaddNewArray(addedElement);
  };

  const handleDelete = (i) => {
    const delValue = [...addNewArray];
    delValue.splice(i, 1);
    setaddNewArray(delValue);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addNewArray];
    list[index][name] = value;
    setaddNewArray(list);
  };
  const handleChangeDate = (value) => {
    const startDate = new Date(value.$d);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDiaryDate(formattedDate);
  };
  //delete func
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleDeleteDiary = async (value) => {
    const result = await deleteGratitudeDailyApi(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      if (editValue && editValue._id === deleteValue._id) {
        setEditValue("");
        setObjectDataAvailable(false);
        setaddNewArray([{ option: "" }, { option: "" }]);
      }
      setRecent((prevRecent) =>
        prevRecent.filter((entry) => entry._id !== deleteValue._id)
      );
      handleGeneralSettingData(result?.gratitude_coins);
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const handleNavigate = () => {
    navigate(`/daily-gratitude/past-activities`);
  };
  const handleEdit = (value) => {
    setEditValue(value);
    setDiaryDate(value?.gratitudeDate);
    setObjectDataAvailable(true);
    if (textareaRef.current) {
      textareaRef.current.focus(); // Focus on the textarea element
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const allNotes = addNewArray.map((item) => item.option);
    let postData = {
      gratitude_text: allNotes,
      gratitude_date: diaryDate,
    };
    const result = await addGratitudeDailyApi(postData);
    if (result.code == 200) {
      handleGeneralSettingData(result?.gratitude_coins);

      setEditValue(result.gratitude);
      setObjectDataAvailable(true);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsSubmit(false);
      getDailyGratitude();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const allNotes = addNewArray.map((item) => item.option);
    let postData = {
      gratitude_text: allNotes,
    };
    const result = await updateGratitudeDailyApi(editValue?._id, postData);

    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      // Update the corresponding object in gratitudeList

      setRecent((prevGratitudeList) =>
        prevGratitudeList.map((gratitude) =>
          gratitude._id === result.gratitude._id
            ? { ...gratitude, gratitude_text: result.gratitude.gratitude_text }
            : gratitude
        )
      );
      const alNotes = result?.gratitude.gratitude_text.map((item) => ({
        option: item,
      }));
      setaddNewArray(alNotes);
      setEditValue(result.gratitude);
      getDailyGratitude();

      setIsSubmit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };
  const getDailyGratitude = async () => {
    setIsFormLoading(true);
    let postData = {
      inputDate: diaryDate,
    };
    const result = await listGratitudeDailyApi(postData);
    let allCoins =
      result.member.affirmation_coins +
      result.member.challenges_coins +
      result.member.goal_coins_count +
      result.member.gratitude_coins +
      result.member.meditation_coins +
      result.member.reward_affirmation_coins +
      result.member.reward_attitude_coins +
      result.member.reward_bonus_coins +
      result.member.reward_gratitude_coins +
      result.member.reward_meditation_coins;

    if (result.code === 200) {
      const recent_gratitudes = result?.latest_gratitudes?.map(
        (gratitude, index) => {
          return {
            ...gratitude,
            gratitude_text: gratitude.gratitude_text,
            gratitudeDate: moment(gratitude.gratitude_date).format(
              "YYYY-MM-DD"
            ),
            gratitude_time: moment(gratitude.gratitude_date).format("hh:mm A"),
          };
        }
      );
      setFirstDate(result?.firstGratitudeDate);
      handleGeneralSettingData(allCoins);
      if (result?.gratitudes.length > 0) {
        const alNotes = result?.gratitudes[0].gratitude_text.map((item) => ({
          option: item,
        }));
        setObjectDataAvailable(true);
        setaddNewArray(alNotes);

        setEditValue(result?.gratitudes[0]);
      } else {
        setObjectDataAvailable(false);
        setaddNewArray([{ option: "" }, { option: "" }]);
        setEditValue("");
      }

      setRecent(recent_gratitudes);
      setIsLoading(false);
      setIsFormLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsFormLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      setEditValue(state);
      setDiaryDate(state?.gratitudeDate);
      setObjectDataAvailable(true);
      location.state = undefined;
    } else {
      getDailyGratitude();
    }
  }, [diaryDate, state]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete gratitude ?"}
        handleAgree={handleDeleteDiary}
      />

      <section className="container dynamite-diary">
        <div className="row">
          <div className="col-lg-6">
            <PageDescription
              parameter="daily_gratitude_heading"
              else_title="Daily Gratitude"
            />
          </div>
          <div className="col-lg-6 text-end"></div>
        </div>
        <section className="container top-section-video mt-3 mb-lg-3">
          <div className="row pt-70" id="assessment-container">
            <div className="col-lg-7 col-md-6 mb-2">
              <ReactNormailVideoPlayer
                url={contentSettingData?.daily_gratitude_url}
              />
            </div>
            <div className="col-lg-5 col-md-6 text-md-start text-center">
              <div
                className="d-flex calender w-100 full-open-calender lg-mt-4 mb-2"
                style={{
                  height: "97%",
                }}
              >
                <ActionBarComponentProps
                  diaryDate={diaryDate}
                  setDiaryDate={setDiaryDate}
                  handleChangeDate={handleChangeDate}
                  firstDiaryDate={firstDate}
                />
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          {isFormLoading ? (
            <>
              <CircularProgress
                className={classes.loadingform}
                color="primary"
              />
              ;
            </>
          ) : (
            <>
              <div className="row">
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contentSettingData?.daily_gratitude_title
                        ? contentSettingData?.daily_gratitude_title
                        : "Write Your Gratitude For Today..",
                    }}
                    className="diary-para"
                  ></div>
                  <form
                    // ref={textareaRef}
                    onSubmit={
                      objectDataAvailable == true ? handleUpdate : handleSubmit
                    }
                  >
                    {addNewArray?.map((data, index) => {
                      return (
                        <div
                          className="d-flex position-relative mt-3"
                          key={index}
                        >
                          <div className="mt-2 field-container">
                            <TextareaAutosize
                              // ref={textareaRef}
                              aria-label="empty textarea"
                              placeholder={`${index + 1}.`}
                              className="text-color textarea-autosize textAreaAutoSize"
                              sx={{ color: "white" }}
                              minRows={1}
                              required
                              name="option"
                              value={data.option}
                              onChange={(e) => handleChange(e, index)}
                            />
                          </div>
                          {/* <div className="icon-container"> */}
                          <span className="cross-icon">
                            <Tooltip title="Add">
                              <AddCircleOutlineIcon
                                // onClick={() => handleAdd()}
                                onClick={() => handleAdd(index)}
                                className="diary-icon-add"
                              />
                            </Tooltip>
                            {addNewArray.length > 1 ? (
                              <Tooltip title="Remove">
                                <RemoveCircleOutlineIcon
                                  onClick={() => handleDelete(index)}
                                  className="diary-icon-remove"
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </span>
                          {/* </div> */}
                        </div>
                      );
                    })}

                    <div className="row mt-2">
                      <div className="col-12">
                        {objectDataAvailable ? (
                          <button className="small-contained-button float-end mt-1 mb-4">
                            {isSubmit ? "Updating..." : "Update"}
                          </button>
                        ) : (
                          <button className="small-contained-button float-end mt-1 mb-4">
                            {isSubmit ? "Saving.." : "Save"}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          {recent?.length > 0 ? (
            <>
              <div className="row">
                <div className="recent col-lg-12 d-flex me-2 pe-3">
                  <h3>Recent Activity</h3>
                </div>
                {recent?.map((value, index) => {
                  return (
                    <div
                      className="col-lg-6 mb-3"
                      // style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <div className="mini-cards d-flex">
                        <div className="w-100 ms-2 me-2  gratitude-text">
                          <div className="diary-font-weight d-flex">
                            <h4 className="normal-font">
                              {value?.gratitudeDate ? value?.gratitudeDate : ""}
                            </h4>
                            <div className="d-flex tools">
                              <CustomPopover menu={MENU_OPTIONS} data={value} />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                            <div>
                              <h4>
                                {" "}
                                {/* {value?.gratitude_text
                                  ? value?.gratitude_text
                                  : ""} */}
                                {value?.gratitude_text
                                  ? value.gratitude_text.map((item, index) => (
                                      <div key={index} className="normal-font">
                                        {index + 1}. {item}
                                      </div>
                                    ))
                                  : ""}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    className="small-contained-button float-end mt-1 mb-4"
                    onClick={handleNavigate}
                  >
                    Past Activities
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
}
