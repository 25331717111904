import React, { createContext, useContext, useEffect, useState } from "react";

const AdminContext = createContext();

export const useAdminContext = () => useContext(AdminContext);
export function ContextAdmin({ children }) {
  const [userInfo, setUserInfo] = useState(null);
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };

  useEffect(() => {
    const user = localStorage.getItem("user_data");
    console.log(user, "useruseruseruseruseruseruseruser");
    if (user) {
      setUserInfo(JSON.parse(user));
      console.log(JSON.parse(user), "JSON.parse(user)JSON.parse(user)");
    }
  }, [localStorage.getItem("user_data")]);

  const collection = {
    setUserInfo,
    userInfo,
    handleUserInfo,
  };
  return (
    <AdminContext.Provider value={collection}>{children}</AdminContext.Provider>
  );
}
